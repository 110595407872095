@use '@styles/_partials' as *;

:global(html[dir='rtl']) .countrySelectContainer {
  @include respond-to($lg) {
    padding-left: 0;
    padding-right: pxToRem(10px);
  }

  .globeAlt {
    margin-right: 0;
    margin-left: pxToRem(5px);
  }

  .countrySelectPopup {
    @include respond-to($lg) {
      left: initial;
      right: 15px;
    }
  }
}
