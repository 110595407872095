@use '@styles/_partials' as *;

:global(html[dir='rtl']) .header {
  .nav {
    @include respond-to-max-width($maxXl) {
      padding-left: pxToRem(55px);
    }
    .navLinkInnerContainer {
      ul {
        @include respond-to($lg) {
          justify-content: left;
        }
      }
    }
  }
}
