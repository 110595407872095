@use '@styles/_partials' as *;
@use './CountrySelect.rtl.scss';

.countrySelectContainer {
  @include respond-to($lg) {
    padding-left: 10px;
  }
}

.countrySelectWrapper {
  position: relative;
  width: fit-content;
  margin: 0 auto;
  display: flex;
  align-items: center;
  @include respond-to($lg) {
    margin-right: 0;
  }

  & .countrySelectOverlay {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: none;

    @include respond-to($lg) {
      display: block;
    }
  }
}

.countrySelect {
  border: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  color: $white;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: left 5px center;
  background-size: 29px 16px;
  padding: pxToRem(10px) 0px pxToRem(10px) 0;
  margin: pxToRem(10px) 0;
  text-overflow: ellipsis;
  text-align: center;
  text-transform: uppercase;
  @include font-size(18px);
  font-family: $primaryFontBold;

  option {
    background: $offBlack;
    text-transform: uppercase;
    &:checked {
      box-shadow: 0 0 10px 100px $darkGray inset;
    }
  }

  // having a font size less than 16px causes the browser to
  // zoom in on focus on iOS
  @include respond-to($lg) {
    @include font-size(16px);
    text-align: end;
    margin: 0;
    padding: 0;
    padding-left: 0;
  }
}

.hiddenSelect {
  visibility: hidden;
  position: absolute;
}

.countrySelectPopup {
  position: absolute;
  display: none;

  @include respond-to($lg) {
    left: 15px;
    .countrySelectWrapper:hover & {
      display: flex;
    }

    select:focus ~ & {
      visibility: hidden;
    }
  }
  background-color: $offBlack;
  top: 100%;
  left: 0;
  color: $ltGray;
  flex-direction: column;
}

.countrySelectorLink {
  color: $white;
  padding: pxToRem(6px) pxToRem(8px);
  display: flex;
  align-items: center;
  white-space: nowrap;

  // EMEAAR-98: Need to make sure Arabic letters have correct font in language switcher
  &[hreflang='ar'] {
    font-family: 'AvenirNextArabicBold', $sansSerifFallback;
  }

  &:hover {
    background-color: $darkGray;
  }

  div ~ span {
    margin-left: pxToRem(8px);
  }
}

.visuallyHidden {
  @include visuallyHidden();
}

.globeAlt,
.globeAlt::after,
.globeAlt::before {
  display: block;
  box-sizing: border-box;
  height: 18px;
  border: 2px solid;
}

.globeAlt {
  position: relative;
  transform: scale(var(--ggs, 1));
  width: 18px;
  border-radius: 22px;
  margin-right: 5px;
}

.globeAlt::after,
.globeAlt::before {
  content: '';
  position: absolute;
  width: 8px;
  border-radius: 100%;
  top: -2px;
  left: 3px;
}

.globeAlt::after {
  width: 24px;
  height: 20px;
  border: 2px solid transparent;
  border-bottom: 2px solid;
  top: -11px;
  left: -5px;
}
