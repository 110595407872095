@use '../../../styles/_partials' as *;
@use './Header.rtl.scss';
@use 'sass:math';

.headerSpacer {
  display: none;
  @include respond-to($lg) {
    display: block;
  }
}

.header {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: pxToRem(40px);
  width: 100%;
  z-index: 100;

  @include respond-to($lg) {
    justify-content: flex-end;
  }

  .topLogo {
    margin-top: pxToRem(60px);
    flex-basis: 100%;
    text-align: center;

    &.desktopOnly {
      display: none;
      margin-top: 0;
      flex-basis: unset;
      position: relative;
      @include respond-to($lg) {
        display: block;
      }
    }

    &.mobileOnly {
      @include respond-to($lg) {
        display: none;
      }
    }
  }

  @include respond-to($lg) {
    position: absolute;
    top: 0;

    .navLinks {
      padding-bottom: pxToRem(18px);
      & .topLogo {
        margin-top: 0;
        margin-bottom: 0;
        svg {
          opacity: 1;
          transform: scale(1);
        }
      }
    }

    &.scrolled {
      position: fixed;
      height: 76px;
      opacity: 1;
      transition: opacity 0.3s, height 0.3s, transform 0.3s;

      &.disableTransitions {
        transition: initial;
      }

      &.hidden {
        opacity: 0;
        transform: translateY(-80px);
      }

      &::before {
        opacity: 1;
        transition: opacity 0.5s;
      }

      .navLinks {
        max-width: unset;
      }

      & .topLogo {
        svg {
          width: 120px;
          height: 40px;
        }
      }
    }
  }

  // a gradient background for when the header is stuck to the top on desktop
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient($offBlack, rgba($offBlack, 0.8) 100%);
    transition: opacity 0.3s;
    opacity: 0;
    z-index: 0;
    pointer-events: none;
    backdrop-filter: blur(4px);
  }
}

.logoLink {
  z-index: 10;
  display: block;
  text-align: center;

  @include respond-to($lg) {
    display: none;
  }
}

.logo {
  height: 48px;
  width: 167px;
  z-index: 10;

  @include respond-to($lg) {
    height: 52px;
    width: 180px;
  }

  :global .logo-pistachios-text path,
  :global .st1 {
    fill: var(--primary-theme-color);
  }
  &.almondsLogo {
    transform: scale(1.2);
  }
}

.nav {
  width: 100%;
  z-index: 100;
  top: 0;
  @include respond-to($lg) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @include respond-to-max-width($maxXl) {
    padding-right: pxToRem(32px);
  }
}

.openNavButton {
  @include respond-to($lg) {
    display: none;
  }

  z-index: 20;

  $buttonSize: 52px;
  $padding: 6px;
  height: $buttonSize;
  width: $buttonSize;
  padding: $padding;
  border: none;
  position: fixed;
  top: 40px;
  right: 20px;
  background-color: transparent;
  color: $white;

  // a little black circle gradient behind the fixed-position nav button,
  // so the button is visible as the user scrolls down
  &::before {
    $gradientSize: 70px;
    content: '';
    width: $gradientSize;
    height: $gradientSize;
    top: math.div(($buttonSize - $gradientSize), 2);
    left: math.div(($buttonSize - $gradientSize), 2);
    position: absolute;
    overflow: visible;
    background-image: radial-gradient(
      circle math.div($gradientSize, 2) at center center,
      rgba($offBlack, 1) 0px,
      rgba($offBlack, 0.75) 80%,
      rgba($offBlack, 0) 100%
    );
    z-index: -2;
  }

  transition: opacity 0.3s;
  &:active {
    opacity: 0.5;
    transition: opacity 0.1s;
  }

  svg {
    fill: transparent;
    height: $buttonSize - ($padding * 2);
    width: $buttonSize - ($padding * 2);
    ellipse,
    line {
      stroke: $white;
    }
  }
}

.navLinks {
  width: 100%;
  min-height: 100vh;
  margin: 0 auto;
  display: flex;
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  flex-direction: column;
  align-items: center;
  background-color: $black;
  flex-wrap: nowrap;

  .navLinkInnerContainer {
    width: 100%;
    ul {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      @include respond-to($lg) {
        flex-wrap: nowrap;
        justify-content: right;
        align-items: center;
      }

      li {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        @include respond-to($lg) {
          display: inline-block;
          margin-top: 5px;
        }
        a {
          white-space: nowrap;
        }
      }
    }

    &.navLinkInnerContainerFirst {
      @include respond-to($lg) {
        text-align: right;
      }
    }
  }

  @include respond-to($lg) {
    visibility: visible;
    position: relative;
    flex-direction: row;
    padding: pxToRem(8px) 0;
    margin: 0;
    min-height: unset;
    background-color: unset;
    transform: none;
    transition: none;

    &::after {
      display: none;
    }
  }

  // the menu animates in from the top, but uses opacity instead if the user has reduced motion on.
  // we translate upwards 200% instead of 100% to leave room for the extra gradient.
  transform: translateY(-200%);
  @media (prefers-reduced-motion: reduce) {
    transform: translateY(0);
    opacity: 0;

    @include respond-to($lg) {
      opacity: 1;
    }
  }

  // by "animating" the change in visibility, we delay that change by the amount of time
  // it takes for the exit animation to run.
  $animationOutTime: 0.3s;
  transition: visibility 0s $animationOutTime,
    transform $animationOutTime ease-in, opacity $animationOutTime;
  // this extra gradient fades out the page as the menu moves down
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    top: 100%;
    left: 0;
    right: 0;
    height: 100%;
    background-image: linear-gradient($black, rgba($black, 0));
  }

  &.visibleMobile {
    visibility: visible;
    transform: translateY(0);
    opacity: 1;
    // this cubic-bezier transition makes the gradient under the menu move down
    // faster than the main menu elements
    transition: transform 0.4s cubic-bezier(0, 0.63, 1, 1), opacity 0.4s;
  }
}

.navLink {
  position: relative;
  color: $white;
  font-family: $primaryFontBold;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80px;

  @include respond-to($lg) {
    @include font-size(16px);
    @include line-height(22);
    transition: color 0.15s;
    width: unset;
    height: unset;

    &::before {
      display: none;
    }

    a {
      padding: 8px 10px;
    }
  }

  // adds a background and shine-wipe to the selected element on mobile
  &::before {
    content: '';
    width: 150%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-image: linear-gradient(
      to right,
      $offBlack,
      $offBlack 67%,
      $darkGray 80%,
      rgba($darkGray, 0) 100%
    );
    transform: translateX(-100%);
    z-index: -1;
  }

  transition: color 0.3s;
  &:hover,
  &:focus,
  &:active,
  &.activePath {
    transition: color 0.15s;
    color: var(--primary-theme-color);

    &::before {
      transform: translateX(0);
      transition: transform 0.3s;
    }
  }

  &:active {
    opacity: 0.7;
  }
}
